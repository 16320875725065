/* src/app/auth/components/auth-body/auth-body.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
a {
  color: #338054;
}
/*# sourceMappingURL=auth-body.component.css.map */
