import { Component } from '@angular/core';

@Component({
  selector: 'pn-auth-bottom',
  standalone: true,
  imports: [],
  templateUrl: './auth-bottom.component.html',
  styleUrl: './auth-bottom.component.scss',
})
export class AuthBottomComponent {}
