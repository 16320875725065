import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '@shared/components/base/button/button.component';

@Component({
  selector: 'pn-auth-head',
  standalone: true,
  imports: [RouterLink, NgIf, ButtonComponent],
  templateUrl: './auth-head.component.html',
  styleUrl: './auth-head.component.scss',
})
export class AuthHeadComponent {
  backLink = input<string>('');
}
